import React, { useState, useEffect } from 'react';
import Button from './Button';
import classes from './Components.module.css';
import Styles from './SignUpPlan.module.css';

const SignUpPlan = ({ plan, onSelectAnnually, onSelectMonthly, active, activeAnnually, activeMonthly, onSignUp }) => {

  const [quotes, setQuotes] = useState([
    {
      quote: "I love Bx! I am so good at it- I get points and my avatar has a dog just like me!",
      quoteBy: "Bx Student"
    },
    {
      quote: "As a parent of a child with autism, I know my kid needs social skill support but I don't know what or how to teach that. Bx gave me a clear direction for teaching and my kid actually wants to participate- the App made getting them involved so much easier!",
      quoteBy: "Bx parent"
    },
    {
      quote: "I use to spend hours prepping materials for teaching social lessons that the kids used 1-2 times and moved on from. With Bx, I just print a few worksheets and grab my IPAD and I have more than enough engaging material to support social and emotional skills for all my kiddos.",
      quoteBy: "Bx therapist"
    }
  ]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTransitioning(true);

      setTimeout(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % quotes.length);
      }, 500);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [quotes.length]);

  useEffect(() => {
    if (transitioning) {
      const timeoutId = setTimeout(() => {
        setTransitioning(false);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [transitioning]);

  const handleDotClick = (index) => {
    setTransitioning(true);
    setCurrentIndex(index);
    setTimeout(() => {
      setTransitioning(false);
    }, 500); 
  };

  return (
    <div className={active ? classes.plan_active : classes.signUpplan}>
      <div className={Styles.signUpBadge}>
        <img src='/images/exluisveoffer1.png' />
        <span className={Styles.signUpBadgeText}>EXCLUSIVE OFFER</span>
      </div>
      <p className={Styles.signupParaTitle}>At Bx Builders, we know that finding engaging and interactive ways to teach neurodivergent youth about emotions and social skills is essential. Our mission is to empower young minds with the necessary tools to understand and manage thoughts and emotions, increase self-awareness, enhance abilities to navigate social interactions with confidence, and learn how to develop and maintain relationships. Teaching neurodivergent youth involves an intricate processes that take time and expertise. As you already know, kids needs  practice with tools across samples and experiences to help them build confidence and fluency.</p>
      <p className={Styles.signupPara}>With lots of practice can come lots of boredom! Get your students engaged with fresh new materials, short and discrete animated videos, and supplemental lessons, worksheets and activities. If you are here, you already know the impact and value of social-emotional skills! When kids understand their own thoughts and emotions, they can unlock skills to control impulsive behaviors, manage difficult emotions and experiences, form positive mindsets, and connect more deeply with others. We believe that every student deserves access to these skills, which is why we’re offering this resource to our teaching hero's! </p>

      <div className={Styles.badgeSection}>
        <img src='/images/unlockbadge2.png' className={Styles.unlockbadgeImage} />
        <div className={Styles.badgeParaSection}>
          <p className={Styles.signupBadgeParaTitle}>Tired of spending hours creating novel and fun ways to get your students engaged in Social-Emotional Learning? Let Bx give you the gift of time! Peak your students attention and interest with the Bx app.</p>
          <p className={Styles.signupBadgeParaTitle1}>Access hundreds of social samples for extensive skills practice, all while you monitor progress with in app data. Supplement your lessons with Bx resources including books, worksheets, lesson guides, and activities designed for neurodivergent learners.</p>
        </div>
      </div>

      <div className={Styles.sliderSection}>
        <span className={Styles.sliderSectionTitle}>
          Don't just take our word for it- listen to these Bx fans!
        </span>
        <div className={Styles.quoteSlider}>
          <div className={`${Styles.quoteContainer} ${transitioning ? Styles.exit : ''} ${!transitioning && Styles.active}`}>
            <img src='/images/quoteFigure.png' className={Styles.quoteImg} />
            <div>
              <p className={Styles.quoteText}>{quotes[currentIndex].quote}</p>
              <p className={Styles.quoteBy}>— {quotes[currentIndex].quoteBy}</p>
            </div>
          </div>
          <div className={Styles.dotsContainer}>
            {quotes.map((_, index) => (
              <span
                key={index}
                className={`${Styles.dot} ${currentIndex === index ? Styles.active : ''}`}
                onClick={() => handleDotClick(index)}
              ></span>
            ))}
          </div>
        </div>
      </div>

      <div className={Styles.conclusionSection}>
        <div className={Styles.badgeParaSection}>
          <p className={Styles.conclusionParaTitle}>For a limited time, experience the full value of Bx Builders at no cost- this exclusive offer won't last long so don't miss out!</p>
          <p className={Styles.conclusionParaTitle1}>
            Download your Bx App today and join us in making a difference!</p>
        </div>
      </div>
      <div className='flex'>
        <Button onClick={() => onSignUp()} style={{ marginTop: '1.2rem' }}>
          Sign up
        </Button>
      </div>
    </div>
  );
};

export default SignUpPlan;
