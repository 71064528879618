import React from 'react';
import IconButton from './IconButton';
import classes from '../Onboard.module.css';

const HeaderTitle = ({ text, onBackPress, style }) => {
  return (
    <div className={classes.headingButtons} style={style}>
      <IconButton icon='long-arrow-left' onClick={onBackPress} />
      <p className={classes.heading}>{text}</p>
    </div>
  );
};

export default HeaderTitle;
